import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  ListSubheader,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { connect } from "react-redux";
import FormTextField from "../form/FormTextField";
import FormFileUploadField from "../form/FormFileUploadFieldV2";
import FlexBox from "../ui-core/FlexBox";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { isEqualData, mapResponseData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getFileUrl } from "../../api/shared/FileApi";
import imgNotFound from "../order-details-dialog/assets/notfound.png";

const enhancer = compose(
  useSheet({
    imageContainer: {
      minHeight: "150px",
      textAlign: "center",
      marginBottom: "6px",
      "& > img": { maxWidth: "250px", maxHeight: "200px" },
    },
  }),
  renderIf("open"),
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  reduxForm({
    form: "UpdateOrderContactDialog",
    enableReinitialize: true,
    validate: ({ name, phone, signatureId }, props) => ({
      name: !name
        ? props.getLocalisationMessage("enter_name", "Enter Name")
        : null,
      phone: !phone
        ? props.getLocalisationMessage(
            "enter_phone_number",
            "Enter Phone Number",
          )
        : null,
      signatureId:
        fp.toFinite(signatureId) <= 0
          ? props.getLocalisationMessage("upload_signature", "Upload Signature")
          : null,
    }),
  }),
  formValues({
    name: "name",
    phone: "phone",
    photoId: "photoId",
    signatureId: "signatureId",
  }),
  mapPropsStream((prop$) => {
    const getPhotoUrlStream = prop$
      .pluck("photoId")
      .filter((id) => fp.isFinite(id) && id > 0)
      .distinctUntilChanged(isEqualData)
      .switchMap((id) =>
        getFileUrl(id).catch((error) => Observable.of({ error })),
      )
      .startWith({})
      .map(mapResponseData)
      .distinctUntilChanged(isEqualData);

    const getSignatureUrlStream = prop$
      .pluck("signatureId")
      .filter((id) => fp.isFinite(id) && id > 0)
      .distinctUntilChanged(isEqualData)
      .switchMap((id) =>
        getFileUrl(id).catch((error) => Observable.of({ error })),
      )
      .startWith({})
      .map(mapResponseData)
      .distinctUntilChanged(isEqualData);

    return prop$.combineLatest(
      getPhotoUrlStream,
      getSignatureUrlStream,
      (props, photoUrl, signatureUrl) => ({
        ...props,
        photoUrl: photoUrl.get("payload"),
        signatureUrl: signatureUrl.get("payload"),
      }),
    );
  }),
);

UpdateOrderContactDialog.propTypes = {
  classes: PropTypes.object,
  photoUrl: PropTypes.string,
  signatureUrl: PropTypes.string,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitSuccess: PropTypes.func,

  title: PropTypes.node,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  getLocalisationMessage: PropTypes.func.isRequired,
};

UpdateOrderContactDialog.defaultProps = { title: "Update Order Contact" };

function UpdateOrderContactDialog(props) {
  const { classes, signatureUrl, photoUrl, getLocalisationMessage } = props;
  return (
    <Dialog
      open={props.open}
      autoScrollBodyContent={true}
      onClose={props.onRequestClose}
      maxWidth="md"
    >
      <PageLoading isLoading={props.submitting} />

      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <form autoComplete={false}>
          <FlexBox
            container={8}
            flex={true}
            direction="column"
            justify="center"
          >
            <FlexBox gutter={8} flex={true}>
              <FlexBox flex={true} direction="column">
                <FormTextField
                  name="name"
                  fullWidth={true}
                  label={getLocalisationMessage("name", "Name")}
                />
              </FlexBox>
              <FlexBox flex={true} direction="column">
                <FormTextField
                  name="phone"
                  fullWidth={true}
                  label={getLocalisationMessage("phone", "Phone")}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox gutter={8} flex={true}>
              <FlexBox
                style={{ textAlign: "center" }}
                flex={true}
                direction="column"
                align="center"
                justify="space-between"
              >
                <ListSubheader>
                  {getLocalisationMessage("photo", "Photo")}
                </ListSubheader>
                <div className={classes.imageContainer}>
                  <img
                    alt={getLocalisationMessage("contact", "Contact")}
                    src={photoUrl || imgNotFound}
                  />
                </div>

                <FormFileUploadField
                  fullWidth={true}
                  name="photoId"
                  label={getLocalisationMessage("upload", "UPLOAD")}
                  accept="image/*"
                />
              </FlexBox>

              <FlexBox
                style={{ textAlign: "center" }}
                flex={true}
                direction="column"
                align="center"
                justify="space-between"
              >
                <ListSubheader>
                  {getLocalisationMessage("signature", "Signature")}
                </ListSubheader>
                <div className={classes.imageContainer}>
                  <img
                    alt={getLocalisationMessage("signature", "Signature")}
                    src={signatureUrl || imgNotFound}
                  />
                </div>

                <FormFileUploadField
                  fullWidth={true}
                  name="signatureId"
                  label={getLocalisationMessage("upload", "UPLOAD")}
                  accept="image/*"
                />
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </form>
      </DialogContent>

      <DialogActions>
        <div>
          <Button onClick={props.onRequestClose}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(UpdateOrderContactDialog);
