import React from "react";
import { endOfToday, startOfToday } from "date-fns";
import fp from "lodash/fp";
import { compose, mapPropsStream, withContext } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  AddCircle as ContentAddCircle,
  Cancel as NavigationCancel,
  ClearAll,
  Close,
  Done,
  RemoveCircle as ContentRemoveCircle,
  Restore,
} from "@material-ui/icons";
import { lightGreen, red } from "@material-ui/core/colors";
import FormCheckbox from "../form/FormCheckbox";
import FormChipInput from "../form/FormChipInput";
import FormDateField from "../form/FormDateField";
import FormTimeField from "../form/FormTimeField";
import FormDriverChips from "../form/FormDriverChips";
import FormSelectField from "../form/FormSelectField";
import FormCustomerChips from "../form/FormCustomerChips";
import FormOrderTagsChips from "../form/FormOrderTagsChips";
import FormWarehouseChips from "../form/FormWarehouseChips";
import FormChipAutoComplete from "../form/FormChipAutoComplete";
import FormCourierTypeChips from "../form/FormCourierTypeChips";
import FormDriverAutoComplete from "../form/FormDriverAutoComplete";
import FormCompanyAutoComplete from "../form/FormCompanyAutoComplete";
import FormWarehouseAutoComplete from "../form/FormWarehouseAutoComplete";
import FormMarketplaceChipAutoComplete from "../form/FormMarketplaceChipAutoComplete";
import FlexBox, { ALIGN_CENTER, JUSTIFY_CENTER } from "../ui-core/FlexBox";
import { pureComponent } from "../../helpers/HOCUtils";
import { isEqualData } from "../../helpers/DataUtils";
import { getObjectId, idsToObjectArray } from "../../helpers/FormUtils";
import {
  formatDateTimeToUrl,
  formatText,
  safeParseDate,
} from "../../helpers/FormatUtils";
import {
  formatLocalisedPaymentType,
  formatMerchantType,
  formatOrderStatusCodeForLocalisation,
} from "../../helpers/OrderHelper";
import { mergeSideEffectStreams } from "../../helpers/StreamUtils";
import DataListFilter from "../../helpers/DataListFilter";
import {
  parseIntString,
  parseString,
  stringifyArray,
} from "../../helpers/SerializeUtils";
import { getMarketplaceDefaultBatchUpdateStatuses } from "../../reducers/MarketplaceReducer";
import { getMessage } from "../../reducers/LocalizationReducer";
import MerchantTypes from "../../constants/MerchantTypes";
import OrderStatusCodes from "../../constants/OrderStatusCodes";
import UpperAddressType from "../../constants/UpperAddressType";
import { filterFormPayTypes } from "../../constants/OrderPaymentTypes";
import FormOperatorAutoComplete from "../form/FormOperatorAutoComplete";
import FormJMChips from "../form/FormJMChips";
import CustomButton, {
  CONTAINED,
  OUTLINED,
  SECONDARY,
} from "../ui-core/CustomButton";

const startTime = startOfToday();
const endTime = endOfToday();
const useStyles = makeStyles({
  marginBottom: {
    marginBottom: "15px",
  },
  marginBottom10: {
    marginBottom: "10px",
  },
  filterName: {
    marginTop: "-8px",
    marginRight: "4px",
  },
  paddingTop: {
    paddingTop: "14px",
  },
  wrapContent: {
    "@media (max-width: 998px)": {
      flexWrap: "wrap",
      justifyContent: "flex-start",
      flexDirection: "column",
      "& > div": { justifyContent: "flex-start", width: "100%" },
    },
  },
  buttonHeightInput: {
    padding: "8px 8px 8px 0",
    "& button": {
      padding: "8px",
    },
  },
});
const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
    orderStatuses: getMarketplaceDefaultBatchUpdateStatuses(state),
  })),
  withContext(
    {
      getCachedDriver: PropTypes.func,
      getDriverPredictions: PropTypes.func,
      getCachedSupplier: PropTypes.func,
      getSupplierPredictions: PropTypes.func,
      getCachedCustomer: PropTypes.func,
      getCustomerPredictions: PropTypes.func,
      getCachedWarehouse: PropTypes.func,
      getWarehousePredictions: PropTypes.func,
      getCachedOwnerUser: PropTypes.func,
      getCachedSalesUser: PropTypes.func,
      getOwnerUserPredictions: PropTypes.func,
      getSalesUserPredictions: PropTypes.func,
      getCachedTimeSlot: PropTypes.func,
      getTimeSlotPredictions: PropTypes.func,
      getCachedServiceType: PropTypes.func,
      getServiceTypePredictions: PropTypes.func,
      getCachedTag: PropTypes.func,
      getTagPredictions: PropTypes.func,
      getCachedCity: PropTypes.func.isRequired,
      getCityPredictions: PropTypes.func.isRequired,
    },
    props => ({
      getCachedDriver: props.getCachedDriver,
      getDriverPredictions: props.getDriverPredictions,
      getCachedSupplier: props.getCachedSupplier,
      getSupplierPredictions: props.getSupplierPredictions,
      getCachedCustomer: props.getCachedCustomer,
      getCustomerPredictions: props.getCustomerPredictions,
      getCachedWarehouse: props.getCachedWarehouse,
      getWarehousePredictions: props.getWarehousePredictions,
      getCachedOwnerUser: props.getCachedOwnerUser,
      getCachedSalesUser: props.getCachedSalesUser,
      getOwnerUserPredictions: props.getOwnerUserPredictions,
      getSalesUserPredictions: props.getSalesUserPredictions,
      getCachedTimeSlot: props.getCachedTimeSlot,
      getTimeSlotPredictions: props.getTimeSlotPredictions,
      getCachedServiceType: props.getCachedServiceType,
      getServiceTypePredictions: props.getServiceTypePredictions,
      getCachedTag: props.getCachedTag,
      getTagPredictions: props.getTagPredictions,
      getCachedCity: props.getCachedCity,
      getCityPredictions: props.getCityPredictions,
    }),
  ),
  mapPropsStream(propsStream => {
    const initialValuesStream = propsStream
      .distinctUntilKeyChanged("filter", isEqualData)
      .map(({ filter: x }) => {
        const includeCustomers = Boolean(
          x.getValue("customer_ids") || !x.getValue("exclude_customer_ids"),
        );
        const includeFromJurisdictions = Boolean(
          x.getValue("from_jurisdiction_ids") ||
            !x.getValue("exclude_from_jurisdiction_ids"),
        );
        const includeToJurisdictions = Boolean(
          x.getValue("to_jurisdiction_ids") ||
            !x.getValue("exclude_to_jurisdiction_ids"),
        );

        const includeDrivers = Boolean(
          x.getValue("driver_ids") || !x.getValue("exclude_driver_ids"),
        );

        const includeSuppliers = Boolean(
          x.getValue("supplier_ids") || !x.getValue("exclude_supplier_ids"),
        );

        const includeHistorySuppliers = Boolean(
          x.getValue("history_supplier_ids") ||
            !x.getValue("exclude_history_supplier_ids"),
        );

        const includeParentHistorySuppliers = Boolean(
          x.getValue("history_parent_supplier_ids") ||
            !x.getValue("exclude_history_parent_supplier_ids"),
        );

        const includeWarehouseHistory = Boolean(
          x.getValue("history_warehouse_ids") ||
            !x.getValue("exclude_history_warehouse_ids"),
        );

        const includeDriversHistory = Boolean(
          x.getValue("history_driver_ids") ||
            !x.getValue("exclude_history_driver_ids"),
        );

        const includeParentSuppliers = Boolean(
          x.getValue("parent_supplier_ids") ||
            !x.getValue("exclude_parent_supplier_ids"),
        );

        const includePickupAttemptCounts = Boolean(
          x.getValue("pickup_attempt_counts") ||
            !x.getValue("exclude_pickup_attempt_counts"),
        );

        const includeParcelHeavyTypes = Boolean(
          x.getValue("parcel_heavy_types") ||
            !x.getValue("exclude_parcel_heavy_types"),
        );

        const includeDeliveryAttemptCounts = Boolean(
          x.getValue("delivery_attempt_counts") ||
            !x.getValue("exclude_delivery_attempt_counts"),
        );

        const searchHistory =
          x.getBoolValue("search_history", false) &&
          Boolean(
            includeHistorySuppliers ||
              includeParentHistorySuppliers ||
              includeWarehouseHistory ||
              includeDriversHistory,
          );

        const includeStatuses = Boolean(
          x.getValue("status") || !x.getValue("excludedStatuses"),
        );

        return {
          isDomestic: x.getBoolValue("is_uae", true),

          filterName: x.getValue("filter_name"),
          isTab: x.getBoolValue("is_tab"),

          deliveryAddressType: x.getValue("deliveryAddressType"),

          includeStatuses,
          statuses: includeStatuses
            ? parseString(x.getValue("status"))
            : parseString(x.getValue("excludedStatuses")),

          orderSize: parseString(x.getValue("order_size")),
          includeException: x.getBoolValue("include_exception", null),
          badAddress: x.getBoolValue("badAddress", null),

          courierTypes: parseString(x.getValue("courier_types")),

          codStatus: parseString(x.getValue("cod_status")),

          company: { id: x.getIntegerValue("company_id") || null },

          cashier: { id: x.getIntegerValue("cashier_id") || null },

          marketplaceIds: parseIntString(x.getValue("marketplaceIds")),
          tagIds: idsToObjectArray(x.getValue("tag_ids")),
          search: parseString(x.getValue("search")),

          includePickupAttemptCounts,
          pickupAttemptCounts: includePickupAttemptCounts
            ? parseString(x.getValue("pickup_attempt_counts"))
            : parseString(x.getValue("exclude_pickup_attempt_counts")),

          includeParcelHeavyTypes,
          parcelHeavyTypes: includeParcelHeavyTypes
            ? parseString(x.getValue("parcel_heavy_types"))
            : parseString(x.getValue("exclude_parcel_heavy_types")),

          includeDeliveryAttemptCounts,
          deliveryAttemptCounts: includeDeliveryAttemptCounts
            ? parseString(x.getValue("delivery_attempt_counts"))
            : parseString(x.getValue("exclude_delivery_attempt_counts")),

          includeCustomers,
          customerIds: includeCustomers
            ? idsToObjectArray(x.getValue("customer_ids"))
            : idsToObjectArray(x.getValue("exclude_customer_ids")),
          includeFromJurisdictions,
          fromJurisdictions: includeFromJurisdictions
            ? idsToObjectArray(x.getValue("from_jurisdiction_ids"))
            : idsToObjectArray(x.getValue("exclude_from_jurisdiction_ids")),
          includeToJurisdictions,
          toJurisdictions: includeToJurisdictions
            ? idsToObjectArray(x.getValue("to_jurisdiction_ids"))
            : idsToObjectArray(x.getValue("exclude_to_jurisdiction_ids")),
          includeDrivers,
          driverIds: includeDrivers
            ? idsToObjectArray(x.getValue("driver_ids"))
            : idsToObjectArray(x.getValue("exclude_driver_ids")),

          includeSuppliers,
          supplierIds: includeSuppliers
            ? idsToObjectArray(x.getValue("supplier_ids"))
            : idsToObjectArray(x.getValue("exclude_supplier_ids")),

          includeParentSuppliers,
          parentSupplierIds: includeParentSuppliers
            ? idsToObjectArray(x.getValue("parent_supplier_ids"))
            : idsToObjectArray(x.getValue("exclude_parent_supplier_ids")),

          // courierTypes: codesToObjectArray(x.getValue("courier_types")),
          pickupTimeslotIds: idsToObjectArray(x.getValue("pickupTimeslotIds")),
          dropoffTimeslotIds: idsToObjectArray(
            x.getValue("dropoffTimeslotIds"),
          ),

          fromDateTime: safeParseDate(x.getValue("from_date_time")),
          toDateTime: safeParseDate(x.getValue("to_date_time")),

          fromPromiseDateTime: safeParseDate(
            x.getValue("from_promise_date_time"),
          ),
          toPromiseDateTime: safeParseDate(x.getValue("to_promise_date_time")),

          statusFromDateTime: safeParseDate(
            x.getValue("status_from_date_time"),
          ),
          statusToDateTime: safeParseDate(x.getValue("status_to_date_time")),

          operator: { id: x.getIntegerValue("operator_id") || null },
          warehouse: { id: x.getIntegerValue("warehouse_ids") || null },
          sourceWarehouse: {
            id: x.getIntegerValue("source_warehouse_id") || null,
          },
          destinationWarehouse: {
            id: x.getIntegerValue("destination_warehouse_id") || null,
          },

          contractOwner: {
            id: x.getIntegerValue("contract_owner_id") || null,
          },

          salesRepresentative: {
            id: x.getIntegerValue("sales_id") || null,
          },

          merchantType: x.getValue("merchantType"),
          logisticType: x.getValue("logistic_type"),

          marketplaceId: x.getIntegerValue("marketplaceId"),
          paymentTypes: parseString(fp.toLower(x.getValue("payment_type"))),

          lastMileDriverIds: {
            id: x.getIntegerValue("last_mile_driver_id") || null,
          },

          historyStatuses: parseString(
            fp.toLower(x.getValue("historyStatuses")),
          ),
          includeHistorySuppliers,
          historySupplierIds: includeHistorySuppliers
            ? idsToObjectArray(x.getValue("history_supplier_ids"))
            : idsToObjectArray(x.getValue("exclude_history_supplier_ids")),

          includeParentHistorySuppliers,
          historyParentSupplierIds: includeParentHistorySuppliers
            ? idsToObjectArray(x.getValue("history_parent_supplier_ids"))
            : idsToObjectArray(
                x.getValue("exclude_history_parent_supplier_ids"),
              ),

          includeWarehouseHistory,
          historyWarehouseIds: includeWarehouseHistory
            ? idsToObjectArray(x.getValue("history_warehouse_ids"))
            : idsToObjectArray(x.getValue("exclude_history_warehouse_ids")),

          includeDriversHistory,
          historyDriverIds: includeDriversHistory
            ? idsToObjectArray(x.getValue("history_driver_ids"))
            : idsToObjectArray(x.getValue("exclude_driver_warehouse_ids")),

          searchHistory,
        };
      })
      .distinctUntilChanged(isEqualData);

    const onSubmit = (values, dispatch, props) =>
      props.onFilterChange(
        props.filter.withMutations((filter: DataListFilter) => {
          filter.setValueMap({
            status: null,
            excludedStatuses: null,
            historyStatuses: null,
            order_size: fp.toUpper(stringifyArray(values.orderSize)),
            include_exception: null,
            customer_ids: null,
            search: null,
            exclude_customer_ids: null,
            from_jurisdiction_ids: null,
            to_jurisdiction_ids: null,
            excluded_from_jurisdiction_ids: null,
            excluded_to_jurisdiction_ids: null,
            driver_ids: null,
            exclude_driver_ids: null,
            filter_name: values.filterName || null,
            is_tab: values.isTab || null,
            supplier_ids: null,
            tag_ids: null,
            exclude_supplier_ids: null,
            parent_supplier_ids: null,
            history_supplier_ids: null,
            exclude_history_supplier_ids: null,
            exclude_parent_supplier_ids: null,
            history_parent_supplier_ids: null,
            exclude_history_parent_supplier_ids: null,
            history_warehouse_ids: null,
            exclude_history_warehouse_ids: null,
            history_driver_ids: null,
            exclude_history_driver_ids: null,
            dropoffTimeslotIds: null,
            pickupTimeslotIds: null,
            status_from_date_time: null,
            status_to_date_time: null,
            marketplaceIds: null,
            page: 0,
            pickup_attempt_counts: null,
            exclude_pickup_attempt_counts: null,
            parcel_heavy_types: null,
            exclude_parcel_heavy_types: null,
            delivery_attempt_counts: null,
            exclude_delivery_attempt_counts: null,
            is_uae: values.isDomestic,
            deliveryAddressType: values.deliveryAddressType || null,
            badAddress: Boolean(values.badAddress) || null,
            courier_types: stringifyArray(values.courierTypes),
            cod_status: stringifyArray(values.codStatus),
            company_id: getObjectId(values.company),
            cashier_id: getObjectId(values.cashier),
            from_date_time: formatDateTimeToUrl(values.fromDateTime),
            to_date_time: formatDateTimeToUrl(values.toDateTime),
            from_promise_date_time: formatDateTimeToUrl(
              values.fromPromiseDateTime,
            ),
            to_promise_date_time: formatDateTimeToUrl(values.toPromiseDateTime),
            operator_id: getObjectId(values.operator),
            warehouse_ids: getObjectId(values.warehouse),
            source_warehouse_id: getObjectId(values.sourceWarehouse),
            destination_warehouse_id: getObjectId(values.destinationWarehouse),
            contract_owner_id: getObjectId(values.contractOwner),
            sales_id: getObjectId(values.salesRepresentative),
            merchantType: values.merchantType,
            logistic_type: values.logisticType,
            search_history: values.searchHistory,
            multi_marketplace: null,
            marketplaceId: null,
            payment_type: fp.toUpper(stringifyArray(values.paymentTypes)),
            last_mile_driver_id: getObjectId(values.lastMileDriverIds),
          });

          if (values.marketplaceId) {
            filter.setValueMap({
              multi_marketplace: true,
              marketplaceId: values.marketplaceId || null,
            });
          }

          if (!fp.isEmpty(values.toJurisdictions)) {
            if (values.includeToJurisdictions) {
              filter.setValueMap({
                to_jurisdiction_ids: stringifyArray(
                  values.toJurisdictions.map(v => v.to).map(getObjectId),
                ),
              });
            } else {
              filter.setValue(
                "excluded_to_jurisdiction_ids",
                stringifyArray(
                  values.toJurisdictions.map(v => v.to).map(getObjectId),
                ),
              );
            }
          }

          if (!fp.isEmpty(values.fromJurisdictions)) {
            if (values.includeFromJurisdictions) {
              filter.setValueMap({
                from_jurisdiction_ids: stringifyArray(
                  values.fromJurisdictions.map(v => v.from).map(getObjectId),
                ),
              });
            } else {
              filter.setValue(
                "excluded_from_jurisdiction_ids",
                stringifyArray(
                  values.fromJurisdictions.map(v => v.from).map(getObjectId),
                ),
              );
            }
          }

          if (!fp.isEmpty(values.dropoffTimeslotIds)) {
            filter.setValue(
              "dropoffTimeslotIds",
              stringifyArray(values.dropoffTimeslotIds.map(getObjectId)),
            );
          }

          if (!fp.isEmpty(values.pickupTimeslotIds)) {
            filter.setValue(
              "pickupTimeslotIds",
              stringifyArray(values.pickupTimeslotIds.map(getObjectId)),
            );
          }

          if (!fp.isEmpty(values.marketplaceIds)) {
            filter.setValue(
              "marketplaceIds",
              stringifyArray(values.marketplaceIds),
            );
          }

          if (!fp.isEmpty(values.search)) {
            filter.setValue("search", stringifyArray(values.search));
          }

          if (!fp.isEmpty(values.statuses)) {
            if (values.includeStatuses) {
              filter.setValueMap({
                status: stringifyArray(values.statuses),
                include_exception: Boolean(values.includeException),
              });
            } else {
              filter.setValueMap({
                excludedStatuses: stringifyArray(values.statuses),
                include_exception: Boolean(values.includeException),
              });
            }
          }

          if (!fp.isEmpty(values.customerIds)) {
            if (values.includeCustomers) {
              filter.setValue(
                "customer_ids",
                stringifyArray(values.customerIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_customer_ids",
                stringifyArray(values.customerIds.map(getObjectId)),
              );
            }
          }
          if (!fp.isEmpty(values.fromJurisdictions)) {
            if (values.includeFromJurisdictions) {
              filter.setValue(
                "from_jurisdiction_ids",
                stringifyArray(values.fromJurisdictions.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_from_jurisdiction_ids",
                stringifyArray(values.fromJurisdictions.map(getObjectId)),
              );
            }
          }
          if (!fp.isEmpty(values.toJurisdictions)) {
            if (values.includeToJurisdictions) {
              filter.setValue(
                "to_jurisdiction_ids",
                stringifyArray(values.toJurisdictions.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_to_jurisdiction_ids",
                stringifyArray(values.toJurisdictions.map(getObjectId)),
              );
            }
          }

          if (!fp.isEmpty(values.tagIds)) {
            filter.setValue(
              "tag_ids",
              stringifyArray(values.tagIds.map(getObjectId)),
            );
          }

          if (!fp.isEmpty(values.driverIds)) {
            if (values.includeDrivers) {
              filter.setValue(
                "driver_ids",
                stringifyArray(values.driverIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_driver_ids",
                stringifyArray(values.driverIds.map(getObjectId)),
              );
            }
          }

          if (!fp.isEmpty(values.pickupAttemptCounts)) {
            if (values.includePickupAttemptCounts) {
              filter.setValue(
                "pickup_attempt_counts",
                stringifyArray(values.pickupAttemptCounts),
              );
            } else {
              filter.setValue(
                "exclude_pickup_attempt_counts",
                stringifyArray(values.pickupAttemptCounts),
              );
            }
          }

          if (!fp.isEmpty(values.parcelHeavyTypes)) {
            if (values.includeParcelHeavyTypes) {
              filter.setValue(
                "parcel_heavy_types",
                stringifyArray(values.parcelHeavyTypes),
              );
            } else {
              filter.setValue(
                "exclude_parcel_heavy_types",
                stringifyArray(values.parcelHeavyTypes),
              );
            }
          }

          if (!fp.isEmpty(values.deliveryAttemptCounts)) {
            if (values.includeDeliveryAttemptCounts) {
              filter.setValue(
                "delivery_attempt_counts",
                stringifyArray(values.deliveryAttemptCounts),
              );
            } else {
              filter.setValue(
                "exclude_delivery_attempt_counts",
                stringifyArray(values.deliveryAttemptCounts),
              );
            }
          }

          if (!fp.isEmpty(values.supplierIds)) {
            if (values.includeSuppliers) {
              filter.setValue(
                "supplier_ids",
                stringifyArray(values.supplierIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_supplier_ids",
                stringifyArray(values.supplierIds.map(getObjectId)),
              );
            }
          }

          if (!fp.isEmpty(values.parentSupplierIds)) {
            if (values.includeParentSuppliers) {
              filter.setValue(
                "parent_supplier_ids",
                stringifyArray(values.parentSupplierIds.map(getObjectId)),
              );
            } else {
              filter.setValue(
                "exclude_parent_supplier_ids",
                stringifyArray(values.parentSupplierIds.map(getObjectId)),
              );
            }
          }

          if (values.searchHistory) {
            filter.setValueMap({
              status_from_date_time: formatDateTimeToUrl(
                values.statusFromDateTime,
              ),
              status_to_date_time: formatDateTimeToUrl(values.statusToDateTime),
            });

            filter.setValue(
              "historyStatuses",
              stringifyArray(values.historyStatuses),
            );

            if (!fp.isEmpty(values.historySupplierIds)) {
              if (values.includeHistorySuppliers) {
                filter.setValue(
                  "history_supplier_ids",
                  stringifyArray(values.historySupplierIds.map(getObjectId)),
                );
              } else {
                filter.setValue(
                  "exclude_history_supplier_ids",
                  stringifyArray(values.historySupplierIds.map(getObjectId)),
                );
              }
            }

            if (!fp.isEmpty(values.historyParentSupplierIds)) {
              if (values.includeParentHistorySuppliers) {
                filter.setValue(
                  "history_parent_supplier_ids",
                  stringifyArray(
                    values.historyParentSupplierIds.map(getObjectId),
                  ),
                );
              } else {
                filter.setValue(
                  "exclude_history_parent_supplier_ids",
                  stringifyArray(
                    values.historyParentSupplierIds.map(getObjectId),
                  ),
                );
              }
            }

            if (!fp.isEmpty(values.historyWarehouseIds)) {
              if (values.includeWarehouseHistory) {
                filter.setValue(
                  "history_warehouse_ids",
                  stringifyArray(values.historyWarehouseIds.map(getObjectId)),
                );
              } else {
                filter.setValue(
                  "exclude_history_warehouse_ids",
                  stringifyArray(values.historyWarehouseIds.map(getObjectId)),
                );
              }
            }

            if (!fp.isEmpty(values.historyDriverIds)) {
              if (values.includeDriversHistory) {
                filter.setValue(
                  "history_driver_ids",
                  stringifyArray(values.historyDriverIds.map(getObjectId)),
                );
              } else {
                filter.setValue(
                  "exclude_history_driver_ids",
                  stringifyArray(values.historyDriverIds.map(getObjectId)),
                );
              }
            }
          }
        }),
      );

    return propsStream
      .combineLatest(initialValuesStream, (props, initialValues) => ({
        ...props,

        onSubmit,
        initialValues,
      }))
      .distinctUntilChanged(isEqualData);
  }),
  reduxForm({ form: "OrderFilterForm", enableReinitialize: true }),
  formValues({
    includeStatuses: "includeStatuses",

    isDomestic: "isDomestic",

    fromJurisdictions: "fromJurisdictions",
    toJurisdictions: "toJurisdictions",
    includeToJurisdictions: "includeToJurisdictions",
    includeFromJurisdictions: "includeFromJurisdictions",
    fromDateTime: "fromDateTime",
    toDateTime: "toDateTime",

    fromPromiseDateTime: "fromPromiseDateTime",
    toPromiseDateTime: "toPromiseDateTime",

    statusFromDateTime: "statusFromDateTime",
    statusToDateTime: "statusToDateTime",

    includeDrivers: "includeDrivers",
    searchHistory: "searchHistory",
    includeCustomers: "includeCustomers",
    includeSuppliers: "includeSuppliers",
    includeHistorySuppliers: "includeHistorySuppliers",
    includeParentHistorySuppliers: "includeParentHistorySuppliers",
    includeWarehouseHistory: "includeWarehouseHistory",
    includeDriversHistory: "includeDriversHistory",
    includeParentSuppliers: "includeParentSuppliers",
    includePickupAttemptCounts: "includePickupAttemptCounts",
    includeParcelHeavyTypes: "includeParcelHeavyTypes",
    includeDeliveryAttemptCounts: "includeDeliveryAttemptCounts",
    marketplaceId: "marketplaceId",
    merchantType: "merchantType",
  }),
  mapPropsStream(propsStream => {
    const sideEffectsStream = mergeSideEffectStreams(
      propsStream
        .map(fp.update("isDomestic", Boolean))
        .distinctUntilKeyChanged("isDomestic")
        .filter(props => props.dirty)
        .do(props => {
          if (props.fromCityId !== null) {
            props.change("fromCityId", null);
          }

          if (props.toCityId !== null) {
            props.change("toCityId", null);
          }
        }),
      propsStream
        .map(fp.update("marketplaceId", Number))
        .distinctUntilKeyChanged("marketplaceId")
        .filter(props => props.dirty)
        .do(props => {
          if (props.customerIds !== null) {
            props.change("customerIds", null);
          }
          if (props.warehouse !== null) {
            props.change("warehouse", null);
          }
        }),
    );

    return propsStream.merge(sideEffectsStream);
  }),
  pureComponent(
    fp.pick([
      "dirty",
      "includeToJurisdictions",
      "includeFromJurisdictions",
      "initialValues",
      "includeStatuses",
      "isDomestic",
      "toDateTime",
      "fromPromiseDateTime",
      "toPromiseDateTime",
      "statusFromDateTime",
      "statusToDateTime",
      "includeDrivers",
      "includeCustomers",
      "includeSuppliers",
      "includeHistorySuppliers",
      "includeParentHistorySuppliers",
      "includeWarehouseHistory",
      "includeDriversHistory",
      "includeParentSuppliers",
      "toggleContainer",
      "includeDeliveryAttemptCounts",
      "includePickupAttemptCounts",
      "includeParcelHeavyTypes",
      "searchHistory",
      "marketplaceId",
    ]),
  ),
);

const styles = {
  toggle: {
    thumbSwitchedStyle: { backgroundColor: "#fdd000" },
    trackSwitchedStyle: { backgroundColor: "#e3bf16" },
  },
  chipAutoComplete: {
    chipContainer: {
      maxHeight: "96px",
      overflowY: "auto",
    },
  },
};

OrderFilterForm.propTypes = {
  dirty: PropTypes.bool,
  reset: PropTypes.func,
  change: PropTypes.func,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  includeStatuses: PropTypes.bool,
  marketplaceId: PropTypes.number,
  fromDateTime: PropTypes.any,
  toDateTime: PropTypes.any,
  statusFromDateTime: PropTypes.any,
  statusToDateTime: PropTypes.any,
  includeDrivers: PropTypes.bool,
  includeCustomers: PropTypes.bool,
  includeToJurisdictions: PropTypes.bool,
  includeFromJurisdictions: PropTypes.bool,
  includeWarehouseHistory: PropTypes.bool,
  includeDriversHistory: PropTypes.bool,
  includePickupAttemptCounts: PropTypes.bool,
  includeDeliveryAttemptCounts: PropTypes.bool,
  onDismiss: PropTypes.func,
  showMarketplace: PropTypes.bool,
  showCompany: PropTypes.bool.isRequired,
  showMerchant: PropTypes.bool.isRequired,
  showCashier: PropTypes.bool,
  showAttemptCounts: PropTypes.bool,
  showAddressType: PropTypes.bool,
  getDriverPredictions: PropTypes.func,
  getWarehousePredictions: PropTypes.func,
  getTagPredictions: PropTypes.func,
  searchHistory: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  orderStatuses: PropTypes.object,
  isCustomerFilter: PropTypes.bool,
};

function OrderFilterForm({
  change,
  dirty,
  fromDateTime,
  getDriverPredictions,
  getLocalisationMessage,
  getTagPredictions,
  getWarehousePredictions,
  handleSubmit,
  includeCustomers,
  includeDeliveryAttemptCounts,
  includeDrivers,
  includeDriversHistory,
  includeFromJurisdictions,
  includePickupAttemptCounts,
  includeStatuses,
  includeToJurisdictions,
  includeWarehouseHistory,
  initialValues,
  isCustomerFilter,
  marketplaceId,
  onDismiss,
  orderStatuses,
  reset,
  searchHistory,
  showAddressType,
  showAttemptCounts,
  showCashier,
  showCompany,
  showMarketplace,
  showMerchant,
  statusFromDateTime,
  statusToDateTime,
  toDateTime,
}) {
  const classes = useStyles();
  const statusList =
    orderStatuses && orderStatuses.size > 0 ? orderStatuses : OrderStatusCodes;
  return (
    <form onSubmit={handleSubmit} style={{ height: "100%" }}>
      <Card
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <CardContent>
          <FlexBox gutter={8} direction="column">
            <FlexBox flex={true} className={classes.marginBottom}>
              <FlexBox gutter={16} flex={true} className={classes.wrapContent}>
                <FlexBox flex={true}>
                  <FormChipAutoComplete
                    name="statuses"
                    fullWidth={true}
                    options={statusList}
                    formatOption={x =>
                      formatOrderStatusCodeForLocalisation(
                        x,
                        getLocalisationMessage,
                      ) || formatText(x)
                    }
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={
                      includeStatuses
                        ? getLocalisationMessage("include_statuses")
                        : getLocalisationMessage("exclude_statuses")
                    }
                    chipContainerStyle={styles.chipAutoComplete.chipContainer}
                  />
                </FlexBox>
                <FlexBox align="flex-end" className={classes.includeButton}>
                  <IconButton
                    onClick={() => change("includeStatuses", !includeStatuses)}
                  >
                    {includeStatuses ? (
                      <ContentAddCircle htmlColor={lightGreen[600]} />
                    ) : (
                      <ContentRemoveCircle htmlColor={red[500]} />
                    )}
                  </IconButton>
                </FlexBox>
                {!isCustomerFilter && (
                  <FlexBox flex={true}>
                    <FormOperatorAutoComplete
                      name="operator"
                      fullWidth={true}
                      hintText={getLocalisationMessage(
                        "type_to_search",
                        "Type to search ...",
                      )}
                      label={getLocalisationMessage("operator", "Operator")}
                    />
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
            <FlexBox flex={true}>
              <FlexBox gutter={16} flex={true} className={classes.wrapContent}>
                <FlexBox flex={true} direction="column" justify="flex-end">
                  <FormChipAutoComplete
                    name="paymentTypes"
                    fullWidth={true}
                    options={filterFormPayTypes}
                    formatOption={x =>
                      formatLocalisedPaymentType(x, getLocalisationMessage)
                    }
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("payment_method")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormCourierTypeChips
                    name="courierTypes"
                    fullWidth={true}
                    hintText={getLocalisationMessage(
                      "type_to_search",
                      "Type To Search...",
                    )}
                    label={getLocalisationMessage(
                      "service_type",
                      "Service Type",
                    )}
                    chipContainerStyle={styles.chipAutoComplete.chipContainer}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox direction="column" flex={true}>
              <FlexBox flex={true} direction="column">
                <ListSubheader>
                  {getLocalisationMessage("created_date")}
                </ListSubheader>
                <FlexBox flex={true} gutter={16}>
                  <FlexBox flex={true}>
                    <FormDateField
                      fullWidth={true}
                      name="fromDateTime"
                      hintText={getLocalisationMessage("from_date")}
                      initialTime={startTime}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTimeField
                      fullWidth={true}
                      name="fromDateTime"
                      hintText={getLocalisationMessage("from_time")}
                    />
                  </FlexBox>

                  {Boolean(fromDateTime) && (
                    <FlexBox className={classes.buttonHeightInput}>
                      <IconButton onClick={() => change("fromDateTime", null)}>
                        <NavigationCancel />
                      </IconButton>
                    </FlexBox>
                  )}

                  <FlexBox flex={true}>
                    <FormDateField
                      fullWidth={true}
                      name="toDateTime"
                      hintText={getLocalisationMessage("to_date")}
                      initialTime={endTime}
                    />
                  </FlexBox>

                  <FlexBox flex={true}>
                    <FormTimeField
                      fullWidth={true}
                      name="toDateTime"
                      hintText={getLocalisationMessage("to_time", "To Time")}
                    />
                  </FlexBox>

                  {Boolean(toDateTime) && (
                    <FlexBox className={classes.buttonHeightInput}>
                      <IconButton onClick={() => change("toDateTime", null)}>
                        <NavigationCancel />
                      </IconButton>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox direction="column">
              <ListSubheader>
                {getLocalisationMessage("jurisdiction")}
              </ListSubheader>
              <FlexBox flex={true}>
                <FlexBox gutter={16} flex={true} align="flex-end">
                  <FlexBox flex={true}>
                    <FormJMChips
                      name="fromJurisdictions"
                      fullWidth={true}
                      label={getLocalisationMessage("jurisdiction_from")}
                    />
                  </FlexBox>
                  <FlexBox
                    align="flex-end"
                    className={classes.buttonHeightInput}
                  >
                    <IconButton
                      onClick={() =>
                        change(
                          "includeFromJurisdictions",
                          !includeFromJurisdictions,
                        )
                      }
                    >
                      {includeFromJurisdictions ? (
                        <ContentAddCircle htmlColor={lightGreen[600]} />
                      ) : (
                        <ContentRemoveCircle htmlColor={red[500]} />
                      )}
                    </IconButton>
                  </FlexBox>
                </FlexBox>
                <FlexBox gutter={16} flex={true} align="flex-end">
                  <FlexBox flex={true}>
                    <FormJMChips
                      name="toJurisdictions"
                      fullWidth={true}
                      label={getLocalisationMessage("jurisdiction_to")}
                    />
                  </FlexBox>

                  <FlexBox
                    align="flex-end"
                    className={classes.buttonHeightInput}
                  >
                    <IconButton
                      onClick={() =>
                        change(
                          "includeToJurisdictions",
                          !includeToJurisdictions,
                        )
                      }
                    >
                      {includeToJurisdictions ? (
                        <ContentAddCircle htmlColor={lightGreen[600]} />
                      ) : (
                        <ContentRemoveCircle htmlColor={red[500]} />
                      )}
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="column">
              <ListSubheader>
                {getLocalisationMessage("warehouses")}
              </ListSubheader>
              <FlexBox gutter={16} flex={true}>
                <FlexBox flex={true}>
                  <FormWarehouseAutoComplete
                    fullWidth={true}
                    name="sourceWarehouse"
                    marketplaceId={marketplaceId}
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("pickup_warehouse")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormWarehouseAutoComplete
                    isDisableShowAll={true}
                    fullWidth={true}
                    name="warehouse"
                    marketplaceId={marketplaceId}
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("warehouse")}
                  />
                </FlexBox>
                <FlexBox flex={true}>
                  <FormWarehouseAutoComplete
                    fullWidth={true}
                    name="destinationWarehouse"
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("destination_warehouse")}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <FlexBox direction="column">
              <ListSubheader>{getLocalisationMessage("others")}</ListSubheader>
              <FlexBox flex={true} gutter={16} align={ALIGN_CENTER}>
                <FlexBox flex={true}>
                  <FormDriverChips
                    name="driverIds"
                    fullWidth={true}
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={
                      includeDrivers
                        ? getLocalisationMessage("include_drivers")
                        : getLocalisationMessage("exclude_drivers")
                    }
                  />
                </FlexBox>
                <FlexBox align="flex-end" className={classes.includeButton}>
                  <IconButton
                    onClick={() => change("includeDrivers", !includeDrivers)}
                  >
                    {includeDrivers ? (
                      <ContentAddCircle htmlColor={lightGreen[600]} />
                    ) : (
                      <ContentRemoveCircle htmlColor={red[500]} />
                    )}
                  </IconButton>
                </FlexBox>
                {Boolean(showCompany && !showCashier) && (
                  <FlexBox flex={true} direction="column" justify="flex-end">
                    <FormCompanyAutoComplete
                      name="company"
                      fullWidth={true}
                      hintText={getLocalisationMessage("type_here_to_search")}
                      label={getLocalisationMessage("company")}
                    />
                  </FlexBox>
                )}
                {showMerchant && (
                  <FlexBox flex={true} direction="column" justify="flex-end">
                    <FormSelectField
                      name="merchantType"
                      fullWidth={true}
                      autoWidth={true}
                      options={MerchantTypes}
                      formatOption={o =>
                        formatMerchantType(o, getLocalisationMessage)
                      }
                      label={getLocalisationMessage("merchant_type")}
                    />
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>
            <FlexBox
              gutter={16}
              flex={true}
              className={[classes.wrapContent, classes.marginBottom10].join(
                " ",
              )}
            >
              {getTagPredictions && (
                <FlexBox flex={true}>
                  <FormOrderTagsChips
                    name="tagIds"
                    fullWidth={true}
                    label={getLocalisationMessage("tags", "Tags")}
                  />
                </FlexBox>
              )}
              {showAddressType && (
                <FlexBox flex={true} align="flex-end">
                  <FormSelectField
                    name="deliveryAddressType"
                    fullWidth={true}
                    formatOption={o =>
                      (o && getLocalisationMessage(o.toLowerCase())) ||
                      formatText(o)
                    }
                    options={UpperAddressType}
                    label={getLocalisationMessage("delivery_address_type")}
                  />
                </FlexBox>
              )}
              {getDriverPredictions && (
                <FlexBox flex={true}>
                  <FormDriverAutoComplete
                    name="lastMileDriverIds"
                    fullWidth={true}
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={getLocalisationMessage("last_mile_driver")}
                  />
                </FlexBox>
              )}
            </FlexBox>
            <FlexBox
              gutter={16}
              flex={true}
              className={[classes.wrapContent, classes.marginBottom10].join(
                " ",
              )}
            >
              {showAttemptCounts && (
                <FlexBox flex={true}>
                  <FlexBox gutter={16} flex={true}>
                    <FlexBox flex={true} align="center">
                      <FormChipInput
                        fullWidth={true}
                        name="pickupAttemptCounts"
                        label={
                          includePickupAttemptCounts
                            ? getLocalisationMessage(
                                "include_pickup_attempt_counts",
                              )
                            : getLocalisationMessage(
                                "exclude_pickup_attempt_counts",
                              )
                        }
                      />
                    </FlexBox>
                    <FlexBox
                      align="flex-end"
                      className={classes.buttonHeightInput}
                    >
                      <IconButton
                        onClick={() =>
                          change(
                            "includePickupAttemptCounts",
                            !includePickupAttemptCounts,
                          )
                        }
                      >
                        {includePickupAttemptCounts ? (
                          <ContentAddCircle htmlColor={lightGreen[600]} />
                        ) : (
                          <ContentRemoveCircle htmlColor={red[500]} />
                        )}
                      </IconButton>
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
              )}
              {showAttemptCounts && (
                <FlexBox gutter={16} flex={true} align="flex-end">
                  <FlexBox flex={true}>
                    <FormChipInput
                      fullWidth={true}
                      name="deliveryAttemptCounts"
                      label={
                        includeDeliveryAttemptCounts
                          ? getLocalisationMessage(
                              "include_delivery_attempt_counts",
                            )
                          : getLocalisationMessage(
                              "exclude_delivery_attempt_counts",
                            )
                      }
                    />
                  </FlexBox>
                  <FlexBox
                    align="flex-end"
                    className={classes.buttonHeightInput}
                  >
                    <IconButton
                      onClick={() =>
                        change(
                          "includeDeliveryAttemptCounts",
                          !includeDeliveryAttemptCounts,
                        )
                      }
                    >
                      {includeDeliveryAttemptCounts ? (
                        <ContentAddCircle htmlColor={lightGreen[600]} />
                      ) : (
                        <ContentRemoveCircle htmlColor={red[500]} />
                      )}
                    </IconButton>
                  </FlexBox>
                </FlexBox>
              )}
              <FlexBox flex={true} align="flex-end">
                <FlexBox flex={true}>
                  <FormCustomerChips
                    name="customerIds"
                    size="medium"
                    fullWidth={true}
                    marketplaceId={marketplaceId}
                    hintText={getLocalisationMessage("type_here_to_search")}
                    label={
                      includeCustomers
                        ? getLocalisationMessage("include_customers")
                        : getLocalisationMessage("exclude_customers")
                    }
                  />
                </FlexBox>
                <FlexBox align="flex-end" className={classes.buttonHeightInput}>
                  <IconButton
                    onClick={() =>
                      change("includeCustomers", !includeCustomers)
                    }
                  >
                    {includeCustomers ? (
                      <ContentAddCircle htmlColor={lightGreen[600]} />
                    ) : (
                      <ContentRemoveCircle htmlColor={red[500]} />
                    )}
                  </IconButton>
                </FlexBox>
              </FlexBox>
            </FlexBox>
            {showMarketplace && (
              <FlexBox gutter={16} flex={true}>
                <FlexBox flex={true}>
                  <FormMarketplaceChipAutoComplete
                    fullWidth={true}
                    name="marketplaceIds"
                    label={getLocalisationMessage("marketplaces")}
                  />
                </FlexBox>
              </FlexBox>
            )}
            {false && (
              <FlexBox flex={true}>
                <FlexBox gutter={16} flex={true}>
                  <FlexBox flex={true} style={{ marginTop: "20px" }}>
                    <FormCheckbox
                      name="searchHistory"
                      label={getLocalisationMessage(
                        "search_history",
                        "Search History",
                      )}
                    />
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
            {searchHistory && (
              <FlexBox flex={true} direction="column">
                <ListSubheader>
                  {getLocalisationMessage(
                    "status_changed_date",
                    "Status Changed Date",
                  )}
                </ListSubheader>
                <FlexBox
                  flex={true}
                  gutter={16}
                  className={classes.wrapContent}
                >
                  <FlexBox flex={true} gutter={16}>
                    <FlexBox flex={true}>
                      <FormDateField
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "from_date",
                          "From Date",
                        )}
                        initialTime={startTime}
                        name="statusFromDateTime"
                        margin="normal"
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTimeField
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "from_time",
                          "From Time",
                        )}
                        name="statusFromDateTime"
                        margin="normal"
                      />
                    </FlexBox>
                    {Boolean(statusFromDateTime) && (
                      <FlexBox>
                        <IconButton
                          onClick={() => change("statusFromDateTime", null)}
                        >
                          <NavigationCancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>
                  <FlexBox flex={true} gutter={16}>
                    <FlexBox flex={true}>
                      <FormDateField
                        fullWidth={true}
                        hintText={getLocalisationMessage("to_date", "To Date")}
                        initialTime={endTime}
                        name="statusToDateTime"
                        margin="normal"
                      />
                    </FlexBox>
                    <FlexBox flex={true}>
                      <FormTimeField
                        fullWidth={true}
                        hintText={getLocalisationMessage("to_time", "To Time")}
                        name="statusToDateTime"
                        margin="normal"
                      />
                    </FlexBox>

                    {Boolean(statusToDateTime) && (
                      <FlexBox>
                        <IconButton
                          onClick={() => change("statusToDateTime", null)}
                        >
                          <NavigationCancel />
                        </IconButton>
                      </FlexBox>
                    )}
                  </FlexBox>
                </FlexBox>
                <FlexBox flex={true}>
                  <FlexBox
                    gutter={16}
                    flex={true}
                    align="flex-end"
                    className={classes.wrapContent}
                  >
                    <FlexBox flex={true}>
                      <FormChipAutoComplete
                        name="historyStatuses"
                        margin="normal"
                        options={statusList}
                        fullWidth={true}
                        hintText={getLocalisationMessage(
                          "type_here_to_search",
                          "Type Here To Search",
                        )}
                        formatOption={x =>
                          formatOrderStatusCodeForLocalisation(
                            x,
                            getLocalisationMessage,
                          ) || formatText(x)
                        }
                        label={getLocalisationMessage(
                          "history_statuses",
                          "History Statuses",
                        )}
                      />
                    </FlexBox>
                  </FlexBox>
                </FlexBox>
                <FlexBox
                  flex={true}
                  gutter={16}
                  className={classes.wrapContent}
                  align={ALIGN_CENTER}
                >
                  {getWarehousePredictions && (
                    <FlexBox gutter={16} flex={true} align="flex-end">
                      <FlexBox flex={true}>
                        <FormWarehouseChips
                          fullWidth={true}
                          name="historyWarehouseIds"
                          margin="normal"
                          hintText={getLocalisationMessage(
                            "type_here_to_search",
                            "Type Here To Search",
                          )}
                          label={getLocalisationMessage(
                            "warehouse_history",
                            "Warehouse History",
                          )}
                        />
                      </FlexBox>
                      <FlexBox
                        align="flex-end"
                        className={classes.buttonHeightInput}
                      >
                        <IconButton
                          onClick={() =>
                            change(
                              "includeWarehouseHistory",
                              !includeWarehouseHistory,
                            )
                          }
                        >
                          {includeWarehouseHistory ? (
                            <ContentAddCircle htmlColor={lightGreen[600]} />
                          ) : (
                            <ContentRemoveCircle htmlColor={red[500]} />
                          )}
                        </IconButton>
                      </FlexBox>
                    </FlexBox>
                  )}
                  {getDriverPredictions && (
                    <FlexBox gutter={16} flex={true} align="flex-end">
                      <FlexBox flex={true}>
                        <FormDriverChips
                          name="historyDriverIds"
                          margin="normal"
                          fullWidth={true}
                          hintText={getLocalisationMessage(
                            "type_here_to_search",
                            "Type Here To Search",
                          )}
                          label={
                            includeDriversHistory
                              ? getLocalisationMessage(
                                  "include_driver_history",
                                  "Include Driver History",
                                )
                              : getLocalisationMessage(
                                  "exclude_driver_history",
                                  "Exclude Driver History",
                                )
                          }
                        />
                      </FlexBox>
                      <FlexBox
                        align="flex-end"
                        className={classes.buttonHeightInput}
                      >
                        <IconButton
                          onClick={() =>
                            change(
                              "includeDriversHistory",
                              !includeDriversHistory,
                            )
                          }
                        >
                          {includeDriversHistory ? (
                            <ContentAddCircle htmlColor={lightGreen[600]} />
                          ) : (
                            <ContentRemoveCircle htmlColor={red[500]} />
                          )}
                        </IconButton>
                      </FlexBox>
                    </FlexBox>
                  )}
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        </CardContent>
        <CardActions
          style={{
            flex: "1 1 auto",
            boxShadow:
              "0px 2px 4px 1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
          }}
        >
          <FlexBox
            gutter={16}
            container={16}
            flex={true}
            justify={JUSTIFY_CENTER}
          >
            <FlexBox>
              {dirty ? (
                <CustomButton
                  startIcon={<Restore />}
                  variant={OUTLINED}
                  color={SECONDARY}
                  onClick={reset}
                >
                  {" "}
                  {getLocalisationMessage("reset", "Reset")}{" "}
                </CustomButton>
              ) : (
                Boolean(onDismiss) && (
                  <CustomButton
                    startIcon={<Close />}
                    variant={OUTLINED}
                    color={SECONDARY}
                    onClick={onDismiss}
                  >
                    {" "}
                    {getLocalisationMessage("dismiss", "Dismiss")}{" "}
                  </CustomButton>
                )
              )}
            </FlexBox>
            <FlexBox>
              <CustomButton
                endIcon={<ClearAll />}
                variant={OUTLINED}
                color={SECONDARY}
                onClick={() => {
                  const includeKeys = [
                    "includeCustomers",
                    "includeDrivers",
                    "includeSuppliers",
                    "includeParentSuppliers",
                    "includePickupAttemptCounts",
                    "includeParcelHeavyTypes",
                    "includeDeliveryAttemptCounts",
                    "includeHistorySuppliers",
                    "includeParentHistorySuppliers",
                    "includeWarehouseHistory",
                    "includeDriversHistory",
                    "statusFromDateTime",
                    "statusToDateTime",
                  ];

                  fp.keys(initialValues).forEach(key => {
                    if (includeKeys.indexOf(key) === -1) {
                      change(key, null);
                    } else {
                      change(key, true);
                    }
                  });

                  change("isDomestic", true);
                }}
              >
                {" "}
                {getLocalisationMessage("clear", "Clear")}{" "}
              </CustomButton>
            </FlexBox>
            <FlexBox>
              <CustomButton
                endIcon={<Done />}
                variant={CONTAINED}
                color={SECONDARY}
                type="submit"
              >
                {" "}
                {getLocalisationMessage("submit", "Submit")}{" "}
              </CustomButton>
            </FlexBox>
          </FlexBox>
        </CardActions>
      </Card>
    </form>
  );
}

export default enhancer(OrderFilterForm);
