import React from "react";
import _ from "lodash";
import useSheet from "react-jss";
import { compose } from "recompose";
import cx from "classnames";
import PropTypes from "prop-types";
import { MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import { getIsRTL } from "../../reducers/LocalizationReducer";

const TOP = "_top";
const SELF = "_self";
const BLANK = "_blank";
const PARENT = "_parent";

const enhancer = compose(
  connect(state => ({
    isRTL: getIsRTL(state),
  })),
  useSheet({
    button: {
      width: "100%",
      textAlign: props => (props.isRTL ? "right" : "left"),
    },
  }),
);

MenuItemForm.propTypes = {
  classes: PropTypes.object,

  url: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  primaryText: PropTypes.string.isRequired,

  onClick: PropTypes.func,

  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  target: PropTypes.oneOf([TOP, SELF, BLANK, PARENT]),
};

MenuItemForm.defaultProps = {
  target: BLANK,
};

function MenuItemForm(props) {
  const { classes } = props;
  const inputs = [];
  const ref = React.createRef();
  _.forEach(props.params, (item, idx) => {
    if (item) {
      inputs.push(<input type="hidden" name={idx} value={item} key={idx} />);
    }
  });

  return (
    <form
      ref={ref}
      method="post"
      action={props.url}
      target={props.target}
      className={props.className}
    >
      {inputs}

      <MenuItem
        button={true}
        onClick={() => {
          if (ref && ref.current) ref.current.submit();
        }}
        className={cx(classes.button, props.buttonClassName)}
      >
        {props.primaryText}
      </MenuItem>
    </form>
  );
}

export default enhancer(MenuItemForm);
