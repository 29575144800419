import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import {
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  ListSubheader,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router";
import FlexBox, { JUSTIFY_CENTER } from "../ui-core/FlexBox";
import { isEqualData, mapResponseData } from "../../helpers/DataUtils";
import { getFileExt } from "../../helpers/FileUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getFileUrl } from "../../api/shared/FileApi";
import imgNotFound from "./assets/notfound.png";
import CustomButton, { OUTLINED } from "../ui-core/CustomButton";
import { CheckCircleSharp } from "@material-ui/icons";

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),

  useSheet({
    cardMedia: { margin: "0 6px", textAlign: "center" },
    card: {
      fontFamily: "Blogger Sans",
      height: "100%",
      maxHeight: "400px",
      minHeight: "300px",
    },
    image: {
      objectFit: "contain",
      width: "250px",
      height: "224px",
      margin: "0 6px",
    },
    file: {
      width: "100%",
      display: "block",
      textAlign: "left",
      paddingLeft: "20px",
    },
  }),
  mapPropsStream(prop$ => {
    const getPhotoUrlStream = prop$
      .pluck("photoId")
      .filter(photoId => fp.isFinite(photoId) && photoId > 0)
      .distinctUntilChanged(isEqualData)
      .switchMap(photoId =>
        getFileUrl(photoId).catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(mapResponseData)
      .distinctUntilChanged(isEqualData);

    const getSignatureUrlStream = prop$
      .pluck("signatureId")
      .filter(signatureId => fp.isFinite(signatureId) && signatureId > 0)
      .distinctUntilChanged(isEqualData)
      .switchMap(signatureId =>
        getFileUrl(signatureId).catch(error => Observable.of({ error })),
      )
      .startWith({})
      .map(mapResponseData)
      .distinctUntilChanged(isEqualData);

    return prop$.combineLatest(
      getPhotoUrlStream,
      getSignatureUrlStream,
      (props, photoUrl, signatureUrl) => ({
        ...props,
        photoUrl: photoUrl.get("payload"),
        signatureUrl: signatureUrl.get("payload"),
      }),
    );
  }),
);

OrderDetailsDialogSignature.propTypes = {
  classes: PropTypes.object,
  header: PropTypes.string,
  verifiedWithFaceId: PropTypes.bool,
  photoUrl: PropTypes.string,
  signatureUrl: PropTypes.string,
  onUpload: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
};

function OrderDetailsDialogSignature(props) {
  const { classes, getLocalisationMessage } = props;

  const fileExt = getFileExt(props.photoUrl);

  return (
    <Card className={classes.card}>
      <CardHeader title={props.header} />

      <CardMedia className={classes.cardMedia}>
        <FlexBox justify="space-around">
          <FlexBox direction="column">
            <ListSubheader>
              {getLocalisationMessage("order_photo", "Order photo")}
            </ListSubheader>
            {fileExt && fileExt === "pdf" ? (
              <Link
                to={props.photoUrl}
                target="_blank"
                className={classes.file}
              >
                {getLocalisationMessage("download_pod", "Download POD")}
              </Link>
            ) : (
              <img
                alt="Photos"
                src={props.photoUrl || imgNotFound}
                className={classes.image}
              />
            )}
          </FlexBox>
          <FlexBox direction="column" flex={true}>
            <ListSubheader>
              {getLocalisationMessage("signature", "Signature")}
            </ListSubheader>
            {props.verifiedWithFaceId ? (
              <FlexBox
                justify="center"
                align="center"
                direction="column"
                style={{ gap: 13, height: "100%" }}
              >
                <CheckCircleSharp
                  style={{ color: "rgb(33, 192, 4)", fontSize: "80px" }}
                />
                <span
                  style={{
                    fontWeight: "bold",
                    color: "rgb(33, 192, 4)",
                    fontSize: "26px",
                  }}
                >
                  {getLocalisationMessage(
                    "verified_with_face_id",
                    "Verified with FACE ID",
                  )}
                  !
                </span>
              </FlexBox>
            ) : (
              <img
                alt={getLocalisationMessage("signature", "Signature")}
                src={props.signatureUrl || imgNotFound}
                className={classes.image}
              />
            )}
          </FlexBox>
        </FlexBox>
      </CardMedia>
      {!props.verifiedWithFaceId && (
        <CardActions>
          <FlexBox
            flex={true}
            justify={JUSTIFY_CENTER}
            style={{ margin: "1rem 0" }}
          >
            <CustomButton variant={OUTLINED} onClick={props.onUpload}>
              {getLocalisationMessage("upload", "Upload")}
            </CustomButton>
          </FlexBox>
        </CardActions>
      )}
    </Card>
  );
}

export default enhancer(OrderDetailsDialogSignature);
