import React from "react";
import { fromJS } from "immutable";
import { getContext } from "recompose";
import PropTypes from "prop-types";
import FormAbstractChips from "./FormAbstractChips";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });
const enhancer = getContext({
  getCachedWarehouse: PropTypes.func.isRequired,
  getWarehousePredictions: PropTypes.func.isRequired,
});

FormWarehouseChips.propTypes = {
  getCachedWarehouse: PropTypes.func,
  getWarehousePredictions: PropTypes.func,

  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
  filter: PropTypes.object,
};

function FormWarehouseChips({
  getCachedWarehouse,
  getWarehousePredictions,
  filter,
  ...props
}) {
  return (
    <FormAbstractChips
      {...props}
      getValue={value =>
        getCachedWarehouse(value).map(item =>
          fromJS({ id: item.get("id"), name: item.get("name") }),
        )
      }
      getPredictions={searchText =>
        getWarehousePredictions(
          baseFilter.setSearch(searchText).setValueMap(filter),
        )
      }
    />
  );
}

FormWarehouseChips.defaultProps = {
  size: "small",
  variant: "outlined",
};

export default enhancer(FormWarehouseChips);
