import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const ADMIN_TAG_URL = `${API_ROOT_URL}/admin/tag`;
const ADMIN_TAG_ITEM_URL = `${API_ROOT_URL}/admin/tag/%s`;
const ADMIN_TAG_CHOOSE_URL = `${API_ROOT_URL}/admin/tag/choose`;

export const getTagList = (request: DataListFilter) =>
  api.getStream(ADMIN_TAG_URL, { params: request.getDefinedValues() });

export const createTag = values => api.post(ADMIN_TAG_URL, { body: values });
export const getTagItem = id => api.getStream(sprintf(ADMIN_TAG_ITEM_URL, id));
export const updateTag = values => api.put(ADMIN_TAG_URL, { body: values });

// const itemCache = new ObservableCache({
//   loader: id =>
//     api
//       .get(sprintf(ADMIN_TAG_ITEM_URL, id))
//       .then(fp.flow(fp.get("data"), fromJS)),
// });
//
// export const getCachedTag = id => itemCache.get(id);
// export const getTagPredictions = (filter: DataListFilter) =>
//   api.getStream(ADMIN_TAG_CHOOSE_URL, { params: filter.getDefinedValues() });

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(ADMIN_TAG_ITEM_URL, id)),
});

const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(ADMIN_TAG_CHOOSE_URL, { params: request.getDefinedValues() }),
});

export const getCachedTag = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));

export const getTagPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);
