import { Map, Set, fromJS, Record } from "immutable";
import { injectReducer } from "../../shared/helpers/ReducerContext";

export const FETCH_SUPPLIER = "SupplierStore/FETCH_SUPPLIER";
const FETCH_SUPPLIER_PENDING = `${FETCH_SUPPLIER}_PENDING`;
const FETCH_SUPPLIER_REJECTED = `${FETCH_SUPPLIER}_REJECTED`;
const FETCH_SUPPLIER_FULFILLED = `${FETCH_SUPPLIER}_FULFILLED`;

export default class SupplierStore extends Record(
  {
    itemMap: Map(),
    itemLoading: Set(),
    itemNotFound: Set(),
  },
  "SupplierStore",
) {
  getSupplier(id) {
    return this.getIn(["itemMap", id]);
  }

  getSupplierName(id) {
    return this.getIn(["itemMap", id, "name"]);
  }

  isSupplierLoading(id) {
    return this.hasIn(["itemLoading", id]);
  }
}

injectReducer("SupplierStore", (state = new SupplierStore(), action) => {
  const { meta, payload } = action;

  switch (action.type) {
    case FETCH_SUPPLIER_PENDING:
      return state.update("itemLoading", item => item.add(meta.id));

    case FETCH_SUPPLIER_FULFILLED:
      return state
        .update("itemLoading", item => item.delete(meta.id))
        .update("itemNotFound", item => item.delete(meta.id))
        .update("itemMap", item => item.set(meta.id, fromJS(payload.data)));

    case FETCH_SUPPLIER_REJECTED:
      return state
        .update("itemMap", item => item.delete(meta.id))
        .update("itemLoading", item => item.delete(meta.id))
        .update(
          "itemNotFound",
          item => (payload.status === 404 ? item.add(meta.id) : item),
        );

    default:
      return state;
  }
});
