import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const REFERENCE_CHOOSE_URL = `${API_ROOT_URL}/references`;
const REFERENCE_ITEM_URL = `${API_ROOT_URL}/references/%s`;

const itemCache = new ObservableCache({
  loader: id =>
    api
      .get(sprintf(REFERENCE_ITEM_URL, id))
      .then(fp.flow(fp.get("data"), fromJS)),
});

export const getCachedReference = id => itemCache.get(id);
export const getReferencePredictions = (request: DataListFilter) =>
  api.getStream(REFERENCE_CHOOSE_URL, { params: request.getDefinedValues() });
