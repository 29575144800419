import { Observable } from "rxjs";
import React from "react";
import { Map, List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import FormChipAutoComplete from "./FormChipAutoComplete";
import { isEqualData } from "../../helpers/DataUtils";
import { getMarketplaces } from "../../api/shared/MarketplaceApi";

const getHeaderOptions = headers => headers.map((key, value) => value).toSet();

const enhancer = compose(
  mapPropsStream(propsStream => {
    const optionsStream = propsStream
      .distinctUntilChanged(isEqualData)
      .switchMap(() =>
        getMarketplaces()
          .map(fp.flow(fp.get("payload.data"), fp.toArray, fromJS))
          .catch(() => Observable.of(List())),
      )
      .distinctUntilChanged(isEqualData)
      .map(v =>
        Map().withMutations(map =>
          v.forEach(item => {
            map.set(item.get("marketplace_id"), item.get("name"));
          }),
        ),
      );

    return propsStream.combineLatest(optionsStream, (props, options) => ({
      ...props,
      options,
    }));
  }),
);

FormMarketplaceChipAutoComplete.propTypes = {
  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,
  name: PropTypes.string.isRequired,
  options: PropTypes.instanceOf(Map),
};

function FormMarketplaceChipAutoComplete(props) {
  const { options } = props;

  return options.size > 0 ? (
    <FormChipAutoComplete
      {...props}
      options={getHeaderOptions(options)}
      formatOption={id => options.get(id)}
    />
  ) : (
    <div />
  );
}

export default enhancer(FormMarketplaceChipAutoComplete);
