import { Observable } from "rxjs";
import React from "react";
import { Map, fromJS } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { Card, CardContent as CardText } from "@material-ui/core";
import { connect } from "react-redux";
import Text, { MUTED, DANGER, SUCCESS } from "../ui-core/Text";
import PriceWrapper from "../ui-core/PriceWrapper";
import DescriptionTerm from "../ui-core/DescriptionTerm";
import { isEqualData } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  useSheet({
    container: { flex: "1 1 0%", display: "flex", flexDirection: "column" },
    row: {
      flex: "1 1 0%",
      display: "flex",
      flexDirection: "row",
      "& > div": {
        margin: "6px",
        flex: "1 1 0%",
        display: "flex",
        fontFamily: "Blogger Sans",
      },
    },
  }),
  mapPropsStream(propsStream => {
    const itemResponseStream = propsStream
      .distinctUntilKeyChanged("driverId", isEqualData)
      .switchMap(props =>
        props
          .getCustomer(props.customerId)
          .catch(error => Observable.of({ error })),
      )
      .map(
        fp.flow(
          fp.update("pending", Boolean),
          fp.update("payload", fp.flow(fp.get("data"), fp.toPlainObject)),
          fromJS,
        ),
      )
      .distinctUntilChanged(isEqualData);

    return propsStream
      .combineLatest(itemResponseStream, (props, itemResponse) => ({
        ...props,
        item: itemResponse.get("payload"),
        isLoading: itemResponse.get("pending"),
      }))
      .distinctUntilChanged(isEqualData);
  }),
);

OrderDetailsDialogCustomer.propTypes = {
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  item: PropTypes.instanceOf(Map),
  getLocalisationMessage: PropTypes.func,
};

function OrderDetailsDialogCustomer(props) {
  const { classes, getLocalisationMessage } = props;
  const NA = props.isLoading
    ? getLocalisationMessage("loading", "Loading")
    : getLocalisationMessage("na", "N/A");

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Card>
          <CardText>
            <DescriptionTerm
              title={getLocalisationMessage("name", "Name")}
              fallbackValue={NA}
            >
              {props.item.get("full_name")}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("login", "Name")}
              fallbackValue={NA}
            >
              {props.item.get("login")}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("email", "Email")}
              fallbackValue={NA}
            >
              {props.item.get("email")}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("phone", "Phone")}
              fallbackValue={NA}
            >
              <Text fallbackValue={NA} fallbackType={MUTED}>
                {props.item.get("phone")}
              </Text>

              {props.item.get("phone") && (
                <Text type={props.item.get("valid_phone") ? SUCCESS : DANGER}>
                  {props.item.get("valid_phone")
                    ? ` ${getLocalisationMessage("validated", "Validated")}`
                    : ` ${getLocalisationMessage(
                        "not_validated",
                        "Not Validated",
                      )}`}
                </Text>
              )}
            </DescriptionTerm>
          </CardText>
        </Card>
        <Card>
          <CardText>
            <DescriptionTerm title={getLocalisationMessage("status", "Status")}>
              <Text type={props.item.get("activated") ? SUCCESS : MUTED}>
                {props.item.get("activated")
                  ? getLocalisationMessage("active", "Active")
                  : getLocalisationMessage("inactive", "Inactive")}
              </Text>
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage(
                "activated_recipient",
                "Activated Recipient",
              )}
            >
              <Text
                type={props.item.get("recipient_activated") ? SUCCESS : MUTED}
              >
                {props.item.get("recipient_activated")
                  ? getLocalisationMessage("enabled", "Enabled")
                  : getLocalisationMessage("disabled", "Disabled")}
              </Text>
            </DescriptionTerm>
            <DescriptionTerm
              title={getLocalisationMessage(
                "sms_notifications",
                "SMS Notifications",
              )}
            >
              <Text type={props.item.get("sms_status") ? SUCCESS : MUTED}>
                {props.item.get("sms_status")
                  ? getLocalisationMessage("enabled", "Enabled")
                  : getLocalisationMessage("disabled", "Disabled")}
              </Text>
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage(
                "push_notifications",
                "Push Notifications",
              )}
            >
              <Text type={props.item.get("push_status") ? SUCCESS : MUTED}>
                {props.item.get("push_status")
                  ? getLocalisationMessage("enabled", "Enabled")
                  : getLocalisationMessage("disabled", "Disabled")}
              </Text>
            </DescriptionTerm>
          </CardText>
        </Card>
        <Card>
          <CardText>
            <DescriptionTerm
              title={getLocalisationMessage("company", "Company")}
              fallbackValue={NA}
            >
              {props.item.getIn(["company", "name"])}
            </DescriptionTerm>

            <DescriptionTerm
              title={getLocalisationMessage("credit_balance", "Credit Balance")}
              fallbackValue={NA}
            >
              {props.item.get("unlimited_credit") ? (
                getLocalisationMessage("unlimited", "Unlimited")
              ) : (
                <PriceWrapper
                  price={props.item.get("total_balance")}
                  // code={props.currencyCode}
                />
              )}
            </DescriptionTerm>

            {/* <DescriptionTerm */}
            {/*  title={getLocalisationMessage("authorities", "Authorities")} */}
            {/*  fallbackValue={NA} */}
            {/* > */}
            {/*  {props.item.get("authorities") && */}
            {/*    props.item.get("authorities").join(", ")} */}
            {/* </DescriptionTerm> */}
          </CardText>
        </Card>
      </div>
    </div>
  );
}

export default enhancer(OrderDetailsDialogCustomer);
