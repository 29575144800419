import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const VEHICLE_CREATE_URL = `${API_ROOT_URL}/admin/vehicle`;
const VEHICLE_ITEM_URL = `${API_ROOT_URL}/admin/vehicle/%s`;
const VEHICLE_CHOOSE_URL = `${API_ROOT_URL}/admin/vehicle/choose`;
const VEHICLE_UPLOAD_URL = `${API_ROOT_URL}/vehicles/upload_excel`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(VEHICLE_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(VEHICLE_CHOOSE_URL, { params: request.getDefinedValues() }),
});

export const getVehicle = id => itemCache.refresh(id);
export const getCachedVehicle = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getVehiclePredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const updateVehicle = (id, body) =>
  api.put(sprintf(VEHICLE_ITEM_URL, id), { body });

type createVehicleRequest = {
  body_type: {
    code: string,
    gparent_code: string,
    id: number,
    name: string,
    parent_code: string,
  },
  car_image: string,
  car_registration_card_image: string,
  cargo_capacity: number,
  color: {
    code: string,
    gparent_code: string,
    id: number,
    name: string,
    parent_code: string,
  },
  driver: {
    description: string,
    id: number,
    name: string,
    phone: string,
  },
  id: number,
  make: {
    code: string,
    gparent_code: string,
    id: number,
    name: string,
    parent_code: string,
  },
  model: {
    code: string,
    gparent_code: string,
    id: number,
    name: string,
    parent_code: string,
  },
  plate_number: string,
  reg_year: number,
  status: string,
  supplier: {
    description: string,
    id: number,
    name: string,
    phone: string,
  },
  type: string,
  vehicle_type: {
    code: string,
    gparent_code: string,
    id: number,
    name: string,
    parent_code: string,
  },
};

export const createVehicle = (body: createVehicleRequest) =>
  api.post(VEHICLE_CREATE_URL, { body });

export const uploadVehicle = file =>
  api.postStream(VEHICLE_UPLOAD_URL, { file });
