import { Map, List } from "immutable";
import fp from "lodash/fp";
import { getSize, getValue } from "./DataUtils";

type LatLng = { lat: number, lng: number };

export const parseToMultipolygon = (polygons = []) =>
  `MULTIPOLYGON(${polygons
    .map(x => `((${x.map(({ lat, lng }) => `${lng} ${lat}`).join(",")}))`)
    .join(",")})`;

export const parseToMultipolygonLeaflet = (polygons = []) =>
  `MULTIPOLYGON(${polygons
    .map(x => `((${x.map(([lat, lng]) => `${lng} ${lat}`).join(",")}))`)
    .join(",")})`;

export const changeLngLatToLatLng = (polygons = []) =>
  polygons.map(x => x.map(([lng, lat]) => [lat, lng]));

export const parseMultipolygon = fp.flow(
  // Trim text to assure that it would be string
  fp.trim,
  // Extract polygons string
  fp.replace(/MULTIPOLYGON \(\((.*)\)\)/, "$1"),
  // Extract child polygons
  x => x.match(/\((.*?)\)/g),
  // Format each polygon
  fp.map(
    fp.flow(
      // Remove bracers
      x => x.replace(/(\(|\))/g, ""),
      // Extract polygon coordinates
      fp.split(","),
      // Parse each coordinate,
      fp.map(
        fp.flow(
          // Trim coordinate string
          fp.trim,
          // Split string to `[lng, lat]` tuple
          fp.split(" "),
          // Convert tuple to object
          ([lng, lat]) => ({ lat: fp.toFinite(lat), lng: fp.toFinite(lng) }),
        ),
      ),
    ),
  ),
);

export const parseMultipolygonLeaflet = fp.flow(
  // Trim text to assure that it would be string
  fp.trim,
  // Extract polygons string
  fp.replace(/MULTIPOLYGON \(\((.*)\)\)/, "$1"),
  // Extract child polygons
  x => x.match(/\((.*?)\)/g),
  // Format each polygon
  fp.map(
    fp.flow(
      // Remove bracers
      x => x.replace(/(\(|\))/g, ""),
      // Extract polygon coordinates
      fp.split(","),
      // Parse each coordinate,
      fp.map(
        fp.flow(
          // Trim coordinate string
          fp.trim,
          // Split string to `[lng, lat]` tuple
          fp.split(" "),
          // Convert tuple to object
          ([lng, lat]) => [fp.toFinite(lat), fp.toFinite(lng)],
        ),
      ),
    ),
  ),
);

export const parseMultipolygonArray = fp.flow(
  // Trim text to assure that it would be string
  fp.trim,
  // Extract polygons string
  fp.replace(/MULTIPOLYGON \(\((.*)\)\)/, "$1"),
  // Extract child polygons
  x => x.match(/\((.*?)\)/g),
  // Format each polygon
  fp.map(
    fp.flow(
      // Remove bracers
      x => x.replace(/(\(|\))/g, ""),
      // Extract polygon coordinates
      fp.split(","),
      // Parse each coordinate,
      fp.map(
        fp.flow(
          // Trim coordinate string
          fp.trim,
          // Split string to `[lng, lat]` tuple
          fp.split(" "),
          // Convert tuple to object
          ([lng, lat]) => [fp.toFinite(lat), fp.toFinite(lng)],
        ),
      ),
    ),
  ),
);

export function pointInPolygon(
  point: LatLng | Map,
  polygon: LatLng[] | List,
): boolean {
  const lat = getValue(point, "lat");
  const lng = getValue(point, "lng");
  const polygonSize = getSize(polygon);

  let inside = false;

  for (let i = 0, j = polygonSize - 1; i < polygonSize; j = i++) {
    const iLat = getValue(polygon, [i, "lat"]);
    const iLng = getValue(polygon, [i, "lng"]);
    const jLat = getValue(polygon, [j, "lat"]);
    const jLng = getValue(polygon, [j, "lng"]);

    const intersect =
      iLng > lng !== jLng > lng &&
      lat < (jLat - iLat) * (lng - iLng) / (jLng - iLng) + iLat;

    if (intersect) {
      inside = !inside;
    }
  }

  return inside;
}

export function findPointPolygon(
  point: LatLng | Map,
  polygons: Array<LatLng[]> | List,
): Map | LatLng[] | null {
  const polygonsSize = getSize(polygons);

  for (let i = 0; i < polygonsSize; i++) {
    const polygon = getValue(polygons, i);

    if (pointInPolygon(point, polygon)) {
      return polygon;
    }
  }

  return null;
}
export function generateHeatColor(
  dataValue: number,
  minValue: number,
  maxValue: number,
) {
  const normalizedValue = (dataValue - minValue) / (maxValue - minValue);

  const aR = 0;
  const aG = 0;
  const aB = 255; // RGB for our 1st color (blue in this case).
  const bR = 255;
  const bG = 0;
  const bB = 0; // RGB for our 2nd color (red in this case).

  const red = (bR - aR) * normalizedValue + aR; // Evaluated as -255*value + 255.
  const green = (bG - aG) * normalizedValue + aG; // Evaluates as 0.
  const blue = (bB - aB) * normalizedValue + aB; // Evaluates as 255*value + 0.

  return `rgb(${red}, ${green}, ${blue})`;
}
