import { Observable } from "rxjs";
import React from "react";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { componentFromStream, compose, createEventHandler } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { Button, CircularProgress } from "@material-ui/core";
import FlexBox from "../ui-core/FlexBox";
import { uploadFile } from "../../api/shared/FileApi";

const enhancer = compose(
  useSheet({
    uploadbtn: {
      display: "none",
    },
    uploadbtnLabel: {
      important: true,
      position: "relative",
      transform: "none",
      padding: "15px 20px",
      borderRadius: "4px",
      background: "rgba(0, 0, 0, 0.04)",
      transition: "0.3s",
      cursor: "pointer",
      "&:hover": {
        background: "rgba(0, 0, 0, 0.08)",
      },
    },
  }),
);
const FileUploadButtonComponent = componentFromStream(propsStream => {
  const { handler: onChange, stream: onChangeStream } = createEventHandler();

  const uploadStream = onChangeStream
    .map(fp.get(["target", "files", 0]))
    .withLatestFrom(propsStream)
    .do(([, props]) => props.input.onChange(null))
    .switchMap(
      ([file]) =>
        uploadFile(file)
          .catch(error => Observable.of({ error }))
          .map(fp.update("payload", fp.get("data.id"))),
      ([, props], response) => [response, props],
    )
    .do(([response, props]) => {
      if (response.payload) {
        props.input.onChange(response.payload);
      }
    })
    .map(fp.nth(0) /* response */)
    .startWith(null);

  return propsStream.combineLatest(
    uploadStream,
    ({ meta, input, label, classes, accept, ...custom }, upload) =>
      upload && upload.pending ? (
        <FlexBox justify="center">
          <CircularProgress
            size={24}
            thickness={2}
            value={upload.progress}
            variant="determinate"
          />
        </FlexBox>
      ) : (
        <label>
          <input
            {...custom}
            type="file"
            value=""
            onChange={onChange}
            onBlur={fp.flow(fp.noop, input.onBlur)}
            onFocus={fp.flow(fp.noop, input.onFocus)}
            style={{ display: "none" }}
            accept={accept}
          />
          <Button component="span">{label}</Button>
        </label>
      ),
  );
});

FormFileUploadField.propTypes = {
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.node,
  label: PropTypes.node,
  classes: PropTypes.object,
};

function FormFileUploadField(props) {
  return (
    <Field
      className={props.classes.uploadbtn}
      {...props}
      component={FileUploadButtonComponent}
    />
  );
}

export default enhancer(FormFileUploadField);
