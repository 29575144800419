import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const CUSTOMER_ITEM_URL = `${API_ROOT_URL}/supplier/customer/%s`;
const CUSTOMER_CHOOSE_URL = `${API_ROOT_URL}/supplier/customer/choose`;
const CUSTOMER_FEEDBACK_URL = `${API_ROOT_URL}/supplier/customer/feedback`;
const CUSTOMER_PAYMENT_METHODS_URL = `${API_ROOT_URL}/supplier/customer/%s/payment_methods`;

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(CUSTOMER_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(CUSTOMER_CHOOSE_URL, { params: request.getDefinedValues() }),
});

export const getCustomer = id => itemCache.refresh(id);
export const getCachedCustomer = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getCustomerPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export type PaymentMethodsRequest = {
  order_id: number,
  package_id: number,
  promo_code: string,
  locations: [
    {
      lat: number,
      lon: number,
      city: string,
      pickup: boolean,
      details: string,
      address: string,
      contact_name: string,
      email: string,
      phone: string,
      company_name: string,
      confirmed_by_recipient: boolean,
      address_type: string,
      postcode: string,
      address_city: string,
      address_street: string,
    },
  ],
};

export const getPaymentMethods = (id, body: PaymentMethodsRequest) =>
  api.post(sprintf(CUSTOMER_PAYMENT_METHODS_URL, id), { body });

export const getCustomerFeedback = (request: DataListFilter) =>
  api.getStream(CUSTOMER_FEEDBACK_URL, { params: request.getDefinedValues() });
