import React from "react";
import { Iterable } from "immutable";
import fp from "lodash/fp";
import useSheet from "react-jss";
import { compose, withState } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { renderIf, pureComponent } from "../../helpers/HOCUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { getMessage } from "../../reducers/LocalizationReducer";
import { getCachedTag, getTagPredictions } from "../../api/admin/AdminTagApi";
import {
  getCachedOwnerUser,
  getCachedSalesUser,
  getOwnerUserPredictions,
  getSalesUserPredictions,
} from "../../api/admin/AdminUserApi";
import {
  getCachedDriver,
  getDriverPredictions,
} from "../../api/admin/AdminDriverApi";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedSupplier,
  getSupplierPredictions,
} from "../../api/admin/AdminSupplierApi";
import {
  getCachedTimeSlot,
  getTimeSlotPredictions,
} from "../../api/admin/AdminTimeSlotApi";
import {
  getCachedWarehouse,
  getWarehousePredictions,
} from "../../api/admin/AdminWarehouseApi";
import {
  getCachedPostcode,
  getPostcodePredictions,
} from "../../api/admin/AdminPostcodesApi";
import {
  getCachedServiceType,
  getServiceTypePredictions,
} from "../../api/admin/AdminServiceTypesApi";
import {
  getCachedCity,
  getCachedCountry,
  getCityPredictions,
  getCountryPredictions,
} from "../../api/shared/CountryV2Api";
import { getSaaSMarketplacesList } from "../../api/shared/MarketplaceApi";
import FlexBox from "../../components/ui-core/FlexBox";
import ModalPaper from "../../components/ui-core/ModalPaper";
import OrderFilterForm from "../../components/orders-core/OrderFilterForm";

const enhancer = compose(
  connect((state) => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  renderIf("open"),
  withState("state", "setState", {
    toggleContainer: null,
    filterNameContainer: null,
  }),
  useSheet({
    paper: {
      "@media (max-width: 998px)": {
        minWidth: "300px",
      },
    },
  }),
  withState("state", "setState", { toggleContainer: null }),
  pureComponent(fp.pick(["state", "filter"])),
);

AdminOrderFilterWrapper.propTypes = {
  classes: PropTypes.object,
  state: PropTypes.object,
  setState: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filter: PropTypes.instanceOf(DataListFilter).isRequired,
  showFinanceMarketplace: PropTypes.bool,
  saasMarketplaces: PropTypes.instanceOf(Iterable),
  getLocalisationMessage: PropTypes.func,
  showCashier: PropTypes.bool,
  isCustomFilter: PropTypes.bool,
  showSearch: PropTypes.bool,
  showLogistic: PropTypes.bool,
};

AdminOrderFilterWrapper.defaultProps = {
  showCashier: false,
  showFinanceMarketplace: false,
  showSearch: false,
  showLogistic: false,
};

function AdminOrderFilterWrapper(props) {
  return (
    <ModalPaper
      open={props.open}
      paperClassName={props.classes.paper}
      title={
        <FlexBox flex={true}>
          <FlexBox>{props.getLocalisationMessage("order_filter")}</FlexBox>

          {props.isCustomFilter && (
            <FlexBox flex={true} justify="center">
              <div
                ref={(x) => props.setState(fp.set("filterNameContainer", x))}
              />
            </FlexBox>
          )}
        </FlexBox>
      }
      onRequestClose={props.onRequestClose}
      rightIcon={
        <div ref={(x) => props.setState(fp.set("toggleContainer", x))} />
      }
    >
      <FlexBox container={8} direction="column">
        <OrderFilterForm
          showLogistic={props.showLogistic}
          showAddressType={true}
          showCompany={true}
          showMerchant={true}
          showAttemptCounts={true}
          showCashier={props.showCashier}
          showSearch={props.showSearch}
          showFinanceMarketplace={props.showFinanceMarketplace}
          showParcelHeavyTypes={true}
          showPromiseDate={true}
          showMarketplace={false}
          filter={props.filter}
          onDismiss={props.onRequestClose}
          onFilterChange={props.onFilterChange}
          toggleContainer={props.state.toggleContainer}
          filterNameContainer={props.state.filterNameContainer}
          getCachedCustomer={getCachedCustomer}
          getCustomerPredictions={getCustomerPredictions}
          getCachedDriver={getCachedDriver}
          getDriverPredictions={getDriverPredictions}
          getCachedSupplier={getCachedSupplier}
          getSupplierPredictions={getSupplierPredictions}
          getCachedWarehouse={getCachedWarehouse}
          getWarehousePredictions={getWarehousePredictions}
          getCachedOwnerUser={getCachedOwnerUser}
          getOwnerUserPredictions={getOwnerUserPredictions}
          getCachedSalesUser={getCachedSalesUser}
          getSalesUserPredictions={getSalesUserPredictions}
          getCachedTimeSlot={getCachedTimeSlot}
          getTimeSlotPredictions={getTimeSlotPredictions}
          getMarketplace={getSaaSMarketplacesList}
          getCachedServiceType={getCachedServiceType}
          getServiceTypePredictions={getServiceTypePredictions}
          getCachedTag={getCachedTag}
          getTagPredictions={getTagPredictions}
          getCachedCity={getCachedCity}
          getCityPredictions={getCityPredictions}
          getCachedCountry={getCachedCountry}
          getCountryPredictions={getCountryPredictions}
          getCachedPostcode={getCachedPostcode}
          getPostcodePredictions={getPostcodePredictions}
        />
      </FlexBox>
    </ModalPaper>
  );
}

export default enhancer(AdminOrderFilterWrapper);
