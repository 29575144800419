import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "./BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const COMPANY_URL = `${API_ROOT_URL}/company/%s`;
const COMPANY_CHOOSE_URL = `${API_ROOT_URL}/company/choose`;

const itemCache = new ObservableCache({
  loader: id =>
    api.get(sprintf(COMPANY_URL, id)).then(fp.flow(fp.get("data"), fromJS)),
});

export const getCachedCompany = id => itemCache.get(id);
export const getCompanyPredictions = (filter: DataListFilter) =>
  api.getStream(COMPANY_CHOOSE_URL, { params: filter.getDefinedValues() });
