import { Observable } from "rxjs";
import React from "react";
import { List, fromJS } from "immutable";
import fp from "lodash/fp";
import { compose, getContext, componentFromStream } from "recompose";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { AutoCompleteComponent } from "./FormAutoComplete";
import { isEqualData, isEqualDataIn } from "../../helpers/DataUtils";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });

const parseInput = (name) => ({ name });
const formatOption = fp.get("name");

const enhancer = compose(
  getContext({
    getCachedReference: PropTypes.func.isRequired,
    getReferencePredictions: PropTypes.func.isRequired,
  }),
);

const ReferenceAutoComplete = enhancer(
  componentFromStream((propsStream) => {
    const valueStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value"))
      .switchMap(({ getCachedReference, input: { value } }) =>
        value && value.id && !value.name
          ? getCachedReference(value.id)
              .map((item) => ({ id: value.id, name: item.get("name") }))
              .catch(() => Observable.of(value))
              .startWith(value)
          : Observable.of(value),
      );

    const optionsStream = propsStream
      .distinctUntilChanged(isEqualDataIn("input.value.name"))
      .switchMap(
        ({
          parentCode,
          gparentCode,
          getReferencePredictions,
          input: { value },
        }) =>
          getReferencePredictions(
            baseFilter.setValueMap({
              search: value.name,
              code: value.code,
              parentCode,
              gparent_code: gparentCode,
            }),
          )
            .map(fp.flow(fp.get("payload.data.list"), fp.toArray, fromJS))
            .catch(() => Observable.of(List())),
      )
      .distinctUntilChanged(isEqualData)
      .map((v) => v.toJS());

    return propsStream
      .map(
        fp.omit([
          "parentCode",
          "gparentCode",
          "getCachedReference",
          "getReferencePredictions",
        ]),
      )
      .combineLatest(valueStream, optionsStream, (props, value, options) => (
        <AutoCompleteComponent
          {...props}
          options={options}
          filter={fp.stubTrue}
          parseInput={parseInput}
          formatOption={
            "formatOption" in props ? props.formatOption : formatOption
          }
          input={{ ...props.input, value }}
        />
      ));
  }),
);

FormReferenceAutoComplete.propTypes = {
  name: PropTypes.string.isRequired,

  readOnly: PropTypes.bool,
  fullWidth: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  parentCode: PropTypes.string,
  gparentCode: PropTypes.string,

  hintText: PropTypes.node,
  label: PropTypes.node,
};

FormReferenceAutoComplete.defaultProps = {
  maxSearchResults: 10,
};

export default function FormReferenceAutoComplete(props) {
  return <Field {...props} component={ReferenceAutoComplete} />;
}
