import { Observable } from "rxjs";
import { parseUrl } from "../../shared/helpers/UrlUtils";

export const readFile = readAs =>
  new Observable(observer => {
    const reader = new FileReader();

    reader.onerror = error => {
      observer.error(error);
      observer.complete();
    };

    reader.onload = event => {
      observer.next(event.target.result);
      observer.complete();
    };

    readAs(reader);

    return () => reader.abort();
  });

export const readFileText = (file: File) =>
  readFile(reader => reader.readAsText(file));

export const getFileExt = url => {
  if (!url) return null;

  const location = parseUrl(url);

  return location.pathname.split(".").pop();
};
