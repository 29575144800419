import React from "react";
import { fromJS } from "immutable";
import { getContext } from "recompose";
import PropTypes from "prop-types";
import FormAbstractChips from "./FormAbstractChips";
import DataListFilter from "../../helpers/DataListFilter";
import { ACTIVE } from "../../constants/OverallStatus";

const baseFilter = new DataListFilter({ page: 0, size: 20, status: ACTIVE });
const enhancer = getContext({
  getCachedTag: PropTypes.func.isRequired,
  getTagPredictions: PropTypes.func.isRequired,
});

FormOrderTagsChips.propTypes = {
  getCachedTag: PropTypes.func,
  getTagPredictions: PropTypes.func,

  fullWidth: PropTypes.bool,
  autoWidth: PropTypes.bool,
  canAutoPosition: PropTypes.bool,

  maxHeight: PropTypes.number,

  openOnFocus: PropTypes.bool,
  clearOnBlur: PropTypes.bool,
  maxItems: PropTypes.number,
  maxSearchResults: PropTypes.number,

  validate: PropTypes.func,

  hintText: PropTypes.node,
  label: PropTypes.node,

  name: PropTypes.string.isRequired,
  filter: PropTypes.object,
};

function FormOrderTagsChips({
  getCachedTag,
  getTagPredictions,
  filter,
  ...props
}) {
  return (
    <FormAbstractChips
      {...props}
      getValue={value =>
        getCachedTag(value).map(item =>
          fromJS({ id: item.get("id"), name: item.get("name") }),
        )
      }
      getPredictions={searchText =>
        getTagPredictions(baseFilter.setSearch(searchText).setValueMap(filter))
      }
    />
  );
}

export default enhancer(FormOrderTagsChips);
