import sprintf from "sprintf";
import { format } from "date-fns";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { ObservableCache } from "../../helpers/loading-cache";
import { api } from "../shared/BaseApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const TIME_SLOT_LIST_URL = `${API_ROOT_URL}/admin/timeslots`;
const TIME_SLOT_CREATE_URL = `${API_ROOT_URL}/admin/timeslot`;
const TIME_SLOT_ITEM_URL = `${API_ROOT_URL}/admin/timeslot/%s`;
const TIME_SLOT_CHOOSE_URL = `${API_ROOT_URL}/admin/timeslot/choose`;

type createTimeSlotRequest = {
  id: number,
  sort_order: number,
  name: string,
  description: number,
  start_time: string,
  end_time: string,
  type: string,
};

const timeSlotMapper = (data: createTimeSlotRequest) => ({
  ...data,
  start_time: format(data.start_time, "HH:mm:ss"),
  end_time: format(data.end_time, "HH:mm:ss"),
});

const itemCache = new ObservableCache({
  expireAfterWrite: 20000,
  loader: id => api.getStream(sprintf(TIME_SLOT_ITEM_URL, id)),
});
const predictionsCache = new ObservableCache({
  expireAfterWrite: 20000,
  keyHasher: fp.method("hashCode"),
  loader: request =>
    api.getStream(TIME_SLOT_CHOOSE_URL, { params: request.getDefinedValues() }),
});

export const getTimeSlot = id => itemCache.refresh(id);
export const getCachedTimeSlot = id =>
  itemCache
    .get(id)
    .takeLast(1)
    .map(fp.flow(fp.get("payload.data"), fromJS));
export const getTimeSlotPredictions = (request: DataListFilter) =>
  predictionsCache.get(request);

export const removeTimeSlot = id => api.delete(sprintf(TIME_SLOT_ITEM_URL, id));
export const updateTimeSlot = body =>
  api.put(sprintf(TIME_SLOT_CREATE_URL), { body: timeSlotMapper(body) });

export const createTimeSlot = (body: createTimeSlotRequest) =>
  api.post(TIME_SLOT_CREATE_URL, { body: timeSlotMapper(body) });

export const getTimeSlotList = request =>
  api.getStream(TIME_SLOT_LIST_URL, { params: request.getDefinedValues() });
