import sprintf from "sprintf";
import { fromJS } from "immutable";
import fp from "lodash/fp";
import { FETCH_SUPPLIER } from "../../stores/SupplierStore";
import WingApi from "../../helpers/WingApi";
import DataListFilter from "../../helpers/DataListFilter";
import { API_ROOT_URL } from "../../../shared/constants/ApiConstants";

const SUPPLIER_LIST_URL = `${API_ROOT_URL}/admin/suppliers`;
const SUPPLIER_CHOOSE_URL = `${API_ROOT_URL}/admin/supplier/choose`;
const SUPPLIER_ITEM_URL = `${API_ROOT_URL}/admin/supplier/%s`;

export function fetchSupplier(id) {
  return (dispatch, getState, api: WingApi) =>
    dispatch({
      meta: { id },
      type: FETCH_SUPPLIER,
      payload: api.get(sprintf(SUPPLIER_ITEM_URL, id)),
    });
}

export const loadSupplierList = request => (dispatch, getState, api: WingApi) =>
  api.getStream(SUPPLIER_LIST_URL, { params: request.getDefinedValues() });

export function loadSupplierPredictions(request: DataListFilter) {
  return (dispatch, getState, api: WingApi) =>
    api
      .getStream(SUPPLIER_CHOOSE_URL, { params: request.getDefinedValues() })
      .map(fp.update("payload", fp.flow(fp.get("data"), fromJS)));
}
